import { APPLICATIONS_API_URL } from '@src/constants/urls';
import { httpDelete, httpGet, httpPost } from '@src/utils/http';

/**
 * Retrieves the Nylas session from the server.
 *
 * @return {Promise<any>} A Promise that resolves with the response from the server.
 */
const getNylasSession = async () => {
  const response = await httpGet(APPLICATIONS_API_URL.GET_NYLAS_SESSION);
  return response;
};

/**
 * Sets the Nylas authorization code on the server.
 *
 * @param {string} code - The Nylas authorization code to set.
 * @return {Promise<any>} A Promise that resolves with the response from the server.
 */
const setNylasCode = async (code: string) => {
  const params = {
    code,
  };
  const response = await httpPost(APPLICATIONS_API_URL.SET_NYLAS_CODE, params);
  return response;
};

/**
 * Retrieves the Nylas connection status from the server.
 *
 * @return {Promise<any>} A Promise that resolves with the response from the server.
 */
const getNylasConnection = async () => {
  const response = await httpGet(APPLICATIONS_API_URL.GET_NYLAS_CONNECTION);

  return response;
};

/**
 * Retrieves CRM applicants from the API, filtered by the given URL query string.
 *
 * @param {string} filterURL - A URL query string to filter the applicants.
 * @return {Promise<any>} A Promise that resolves with the response from the API.
 */
const getMarketingCRMApplicants = async (filterURL: string) => {
  let url = APPLICATIONS_API_URL.GET_MARKETING_CRM_APPLICANTS;

  if (filterURL) url += `?${filterURL}`;

  const response = await httpGet(url);

  return response;
};

/**
 * Retrieves marketing CRM applications from the API, filtered by the given user ID.
 *
 * @param {number} id - The user ID to filter the applications by.
 * @return {Promise<any>} A Promise that resolves with the response from the API.
 */
const getMarketingCRMApplications = async (id: number) => {
  let url = APPLICATIONS_API_URL.GET_MARKETING_CRM_APPLICATIONS.replace(
    '#MAIL_USER_ID#',
    String(id)
  );

  url += '?limit=1000000';

  const response = await httpGet(url);

  return response;
};

/**
 * Retrieves marketing CRM analytics from the server.
 *
 * @return {Promise<any>} A Promise that resolves with the response from the server.
 */
const getMarketingCRMAnalytics = async (filterURL: string) => {
  let url = APPLICATIONS_API_URL.GET_MARKETING_CRM_ANALYTICS;

  if (filterURL) url += `?${filterURL}`;

  const response = await httpGet(url);

  return response;
};

/**
 * Deletes the Nylas connection.
 *
 * @return {Promise<any>} A Promise that resolves with the response from the server.
 */
const deleteNylasConnection = async () => {
  const response = await httpDelete(
    APPLICATIONS_API_URL.DELETE_NYLAS_CONNECTION
  );
  return response;
};

export {
  getNylasSession,
  setNylasCode,
  getNylasConnection,
  getMarketingCRMApplicants,
  getMarketingCRMApplications,
  getMarketingCRMAnalytics,
  deleteNylasConnection,
};
