import { useContext } from 'react';
import NylasContext from '@src/contexts/connectNylas';

const useNylas = () => {
  const context = useContext(NylasContext);
  if (context === undefined) {
    throw new Error('useNylas must be used within an app');
  }

  return context;
};

export default useNylas;
