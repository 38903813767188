import { createContext } from 'react';

interface SentNylasCodeProps {
  code: string;
  onNylasConnect: () => void;
}

interface NylasContextProps {
  isLoading: boolean;
  connectNylas: () => void;
  // eslint-disable-next-line no-unused-vars
  sentNylasCode: ({ code, onNylasConnect }: SentNylasCodeProps) => void;
}

const NylasContext = createContext<NylasContextProps>({} as NylasContextProps);

export default NylasContext;
